<template>
  <div>
    <b-breadcrumb :items="crumb_links"></b-breadcrumb>
    <div class="flex text-left p-2">
      <span class="header">Contact Information for TAIR</span>
      <br />
      <p>
        For general questions or problems with the data or database, please
        contact the TAIR curator:
        <a
          href="javascript:location.href='mailto:curator@arabidopsis.org?SUBJECT=' + document.title + '&amp;BODY=' +'URL: ' + escape(location.href)"
          >curator@arabidopsis.org</a
        >
      </p>
      <p>
        For questions about subscriptions, please contact our subscription team:
        <a
          href="javascript:location.href='mailto:subscriptions@phoenixbioinformatics.org?SUBJECT=' + document.title + '&amp;BODY=' +'URL: ' + escape(location.href)"
          >subscriptions@phoenixbioinformatics.org</a
        >
      </p>
      Phoenix Bioinformatics Corporation<br />
      39899 Balentine Drive, Suite 200<br />
      Newark, CA 94560, USA<br />
      <br />
      Phone: +1 (650) 995-7502<br /><br />
      <br />
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {
      crumb_links: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Contact",
          active: true,
        },
      ],
    };
  },
  mounted() {
    document.title = "About TAIR";
  },
  methods: {
    sideColClicked(index) {
      window.open(this.searchOptions[index].route, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.overview-wrapper {
  width: 100vw;
  display: flex;
  background-color: #f1efec;
  border: 1px solid #e0e0e0;
  padding: 20px;
  .breadcrumb {
    padding: 10px 0;
    font-weight: bold;
    font-size: 18px;
  }
}
</style>
